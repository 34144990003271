import styled from '@emotion/styled';
import { GRID } from 'radiance-ui/lib/constants';

export const ContentContainer = styled.div<{
  size?: string;
}>`
  margin: 0 auto;
  position: relative;
  padding: 0;

  ${({ size }): string => {
    switch (size) {
      case 'xs':
        return `max-width: ${GRID.xsContentMaxWidth};`;
      case 'sm':
        return `max-width: ${GRID.smContentMaxWidth};`;
      case 'xl':
        return `max-width: ${GRID.xlContentMaxWidth};`;
      default:
        return `max-width: ${GRID.xsContentMaxWidth};`;
    }
  }};
`;

export default ContentContainer;

import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { SPACER } from 'radiance-ui/lib/constants';

export const SignupNavContainer = styled.div<{ backHidden: boolean }>`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  padding: ${SPACER.x2large} 0 0;
  margin-bottom: ${SPACER.xlarge};

  ${({ backHidden }) =>
    !backHidden &&
    css`
      & #NextButton {
        margin-left: ${SPACER.xlarge};
      }
    `};
`;

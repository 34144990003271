import styled from '@emotion/styled';
import { Typography, TYPOGRAPHY_STYLE } from 'radiance-ui';
import { SPACER, MEDIA_QUERIES } from 'radiance-ui/lib/constants';

interface PageIntroStylesProps {
  align: string;
}

export const PageIntroStyles = styled.div<PageIntroStylesProps>`
  margin: ${SPACER.small} auto ${SPACER.xlarge};
  position: relative;

  ${MEDIA_QUERIES.smUp} {
    text-align: ${({ align }) => align};
  }

  & p {
    margin-bottom: ${SPACER.small};
  }
`;

export const SectionTitle = styled(Typography.Heading)`
  margin-bottom: ${SPACER.small};
  position: relative;
  z-index: 1;
`;

export const PageTitle = styled(Typography.Title)`
  margin-bottom: ${SPACER.small};
  position: relative;
  z-index: 1;
`;

export const QuestionTitle = styled(SectionTitle)`
  ${MEDIA_QUERIES.smUp} {
    margin-top: ${SPACER.large};
  }
`;

export const QuestionTitleSmall = styled(QuestionTitle)`
  font-size: 20px;
`;

export const PageText = styled.p`
  ${({ theme }) => TYPOGRAPHY_STYLE.body(theme)};
  position: relative;
  z-index: 1;
`;

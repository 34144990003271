import React, { ReactNode } from 'react';
import { v4 } from 'uuid';

import {
  PageIntroStyles,
  PageText,
  SectionTitle,
  QuestionTitle,
  QuestionTitleSmall,
} from './style';

type RenderedType = string | ReactNode | (string | ReactNode)[];

interface PageIntroProps {
  isSection?: boolean;
  title: RenderedType;
  titleSize?: string;
  text?: RenderedType;
  textAlign?: string;
  children?: ReactNode;
}

function getMarkup(text: string) {
  return { __html: text };
}

function renderComponentFromText(
  text: string | ReactNode,
  Component: typeof PageText | typeof SectionTitle | typeof QuestionTitle,
) {
  if (typeof text === 'string') {
    return <Component key={v4()} dangerouslySetInnerHTML={getMarkup(text)} />;
  }

  return <Component key={v4()}>{text}</Component>;
}

function renderChunks(
  text: RenderedType,
  Component: typeof PageText | typeof SectionTitle | typeof QuestionTitle,
): ReactNode | ReactNode[] {
  if (Array.isArray(text)) {
    return text
      .filter((chunk) => !!chunk)
      .map((chunk) => renderComponentFromText(chunk, Component));
  }

  return renderComponentFromText(text, Component);
}

const PageIntro = ({
  title,
  titleSize = 'large',
  text = null,
  textAlign = 'center',
  children = null,
  isSection = false,
}: PageIntroProps) => {
  let titleComponent = QuestionTitle;

  if (titleSize === 'small') {
    titleComponent = QuestionTitleSmall;
  } else if (isSection) {
    titleComponent = SectionTitle;
  }

  return (
    <PageIntroStyles align={textAlign}>
      {renderChunks(title, titleComponent)}
      {!!text && renderChunks(text, PageText)}
      {children}
    </PageIntroStyles>
  );
};

export default PageIntro;
